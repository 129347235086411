import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "../components/Modal";
import { csrfToken } from "../includes/getCSRF";
import { Checkbox } from "./fields/Checkbox";
import { InputField } from "./fields/InputField";
import { RadioButtons } from "./fields/RadioButtons";
import { SearchSelect } from "./fields/SearchSelect";
import { Select } from "./fields/Select";
import { ToggleGroup } from "./fields/ToggleGroup";
import { UserSearch } from "./fields/UserSearch";


export const ApplicationForm = ({ fields }) => {
	const methods = useForm({
		defaultValues: {
			organisation: fields["organisation"].value,
		},
	});
	const onSubmit = (data, event) => {
		if (submitting) return;

		const formData = new FormData();

		for (const [key, value] of Object.entries(data)) {
			if (value instanceof FileList || value instanceof Array) {
				for (let i = 0; i < value.length; i++) {
					formData.append(key, value[i]);
				}
			} else {
				formData.append(key, value);
			}
		}
		formData.append("csrfmiddlewaretoken", csrfToken());

		setSubmitting(true);
		fetch("/app/applications/create/", {
			method: "POST",
			body: formData,
		}).then((response) => {
			if (response.redirected) {
				window.location.href = response.url;
			} else {
				setSubmitting(false);
				response.json().then((json) => {
					for (const [key, value] of Object.entries(json)) {
						if (value.length > 1) {
							const errors = value.reduce((a, v) => ({ ...a, [v['code']]: v['message']}), {})
							methods.setError(key, {
								types: errors
							}, { shouldFocus: true });
						} else {
							methods.setError(key, {
								type: "manual",
								message: value[0],
							},
							{ shouldFocus: true });
						}
					}
				});
			}
		});
	};

	const [submitting, setSubmitting] = React.useState(false);
	const [courses, setCourses] = React.useState([]);
	const [orgEditUrl, setOrgEditUrl] = React.useState(null);
	const [showDialog, setShowDialog] = React.useState(false);
	const [currentOrganisation, setCurrentOrganisation] = React.useState(null);
	const [showNotify, setShowNotify] = React.useState(false);

	const trainingProvider = methods.watch("training_provider");
	const course = methods.watch("course");
	const organisation = methods.watch("organisation");
	const startDate = methods.watch("course_start_date");
	const deliveryFormat = methods.watch("delivery_format");

	const fee = methods.watch("course_fee_ex_gst");
	const gst = methods.watch("gst_amount");

	let clearForm = () => {
		methods.reset({ organisation: organisation });
	};

	React.useEffect(() => {
		let cost = 0
		if (fee) {
			cost += parseFloat(fee)
		}
		if (gst) {
			cost += parseFloat(gst)
		}
		// two decimal places
		cost = cost.toFixed(2)
		methods.setValue("cost", cost)

	}, [fee, gst])

	React.useEffect(() => {
		if (organisation && organisation != currentOrganisation) {
			clearForm();
			fetch(`/app/organisations/api/${organisation}/info/`)
				.then((response) => response.json())
				.then((json) => {
					if (json["edit_url"]) {
						setOrgEditUrl(json["edit_url"]);
					}
					setShowNotify(!json["is_main_contact"])
				});


			setCurrentOrganisation(organisation);
		}
	}, [organisation]);

	React.useEffect(() => {
		if (trainingProvider) {
			fetch(`/app/applications/api/${trainingProvider}/courses/`)
				.then((response) => response.json())
				.then((json) => {
					methods.setValue("course", "");
					setCourses(json);
				});
		}
	}, [trainingProvider]);

	React.useEffect(() => {
		if (startDate) {
			fetch(`/app/applications/api/date-check/?date=${startDate}`)
				.then((response) => response.json())
				.then((json) => {
					if (json["error"]) {
						methods.setError("course_start_date", {
							type: "manual",
							message: json["error"],
						});
					} else {
						methods.clearErrors("course_start_date");
					}
				});
		}
	}, [startDate]);

	React.useEffect(() => {
		if (deliveryFormat === "online") {
			methods.setValue("location", "")
		}
	}, [deliveryFormat]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)} className="app-v-rhythm">
				{fields["organisation"]["choices"].length > 1 ? (
					<>
						<ToggleGroup {...fields["organisation"]} type="radio" label={false} />
						<hr />
					</>
				) : (
					<input
						{...methods.register("organisation")}
						type="hidden"
						name="organisation"
						value={fields["organisation"]["value"]}
					/>
				)}


				{organisation && (
					<div className="flex flex-col gap-3">
						<h2 className="mb-5 app-page-subheading">Course details</h2>
						<SearchSelect {...fields['training_provider']} cla />
						{trainingProvider && (
							<SearchSelect {...fields["course"]} choices={courses} className={"mt-5"} />

						)}
						<span
							className="normal-case cursor-pointer link"
							onClick={() => setShowDialog(true)}
						>
							Having trouble finding your course?
						</span>
						<Modal
							title="Course Missing?"
							isOpen={showDialog}
							closeModal={() => setShowDialog(false)}
						>
							<p>
								If your training provider or course is not listed here, they may not be currently approved for Keystone training rebate. You can view our approved courses and training providers <a href="https://keystone.com.au/training/rebate/" className="app-page-link" target="_blank">HERE</a> or contact your training provider.
							</p>
						</Modal>
					</div>
				)}

				{course && (
					<>
						<RadioButtons {...fields["part_of_training_contract"]} />
						<RadioButtons {...fields["rlp_or_voc"]} />
						<hr />
						<div>
							<h3 className="app-page-subheading">Course Fee</h3>
							<p className='mt-4'>
								Provide the total out-of-pocket course fee for all the attendees as quoted or charged by the training provider. Enter GST if charged by the training provider.
							</p>
							<p className='mt-2 text-sm'>
								*Please note that the training rebate applies only to the fee for training. Keystone does not fund travel, accommodation, catering, venue or equipment hire, or other incidental costs.
							</p>

							<div className="flex flex-col gap-5 mt-7 md:flex-row md:items-center">
								<div className='md:w-72'>
									<InputField
										{...fields['course_fee_ex_gst']}
										type="number"
										extra={{ step: 0.01 }}
										validation={{
											min: {
												value: 0.1,
												message: "Cost must be greater than 0",
											},
										}}
									/>
								</div>
								<div className="md:w-32">
									<InputField
										{...fields["gst_amount"]}
										type="number"
										extra={{ step: 0.01 }}
										validation={{
											required: { value: true, message: "This field is required. If there is no GST, please enter 0." }
										}}
									/>
								</div>
							</div>
							<div className="mt-5 md:w-72">
								<InputField
									{...fields["cost"]}
									type="number"
									extra={{ step: 0.01 }}
									disabled={true}
								/>
							</div>
						</div>
						<hr />
						<h2 className="app-page-subheading">Date and Format</h2>
						<InputField {...fields["course_start_date"]} type="date" extra={{ 'min': '2019-01-01' }} />
						<InputField {...fields["course_end_date"]} type="date" extra={{ 'min': '2019-01-01' }} />
						<Select {...fields["delivery_format"]} />
						<Select {...fields["location"]} disabled={deliveryFormat == "online"} />
						<InputField {...fields["nominal_hours"]} type="number"
							extra={{ step: 0.1, min: 0, max: 9999.99 }}
							validation={{
								min: {
									value: 0.1,
									message: "Nominal hours must be greater than 0",
								},
								max: {
									value: 9999.99,
									message: "Nominal hours must be less than 9999.99",
								}
							}}
						/>
						<hr />
						<div className="flex flex-col gap-2">
							<h2 className="app-page-subheading">Attendees</h2>
							<p className='mb-5'>Add your employees who will be attending the course</p>
							<UserSearch {...fields["employees"]} orgId={organisation} />
							{orgEditUrl && (
								<div>
									*Can't find an attendee? Employee details must be added to your account's{" "}
									<a
										href={orgEditUrl}
										target="_blank"
										className="capitalize app-page-link"
									>
										My Employees
									</a>
									{" "}section before they can be included in an application.
								</div>
							)}
						</div>
						{showNotify && (
							<>
								<hr />
								<h2 className="app-page-subheading">Notifications</h2>
								<Checkbox {...fields["notify_owner"]} />

							</>

						)}
						<hr />

						<h2 className="app-page-subheading">Declaration</h2>
						<div className="max-w-full prose">
							<Checkbox {...fields["confirm_1"]} />
							<p>I understand that:</p>
							<div className="flex flex-col gap-2">
								<Checkbox {...fields["confirm_2"]} />
								<Checkbox {...fields["confirm_3"]} />
								<Checkbox {...fields["confirm_4"]} />
								<Checkbox {...fields["confirm_5"]} />
							</div>
						</div>
						<button type="submit" className="app-page-primary-btn">
							Save & Next
						</button>
					</>
				)}
			</form>
		</FormProvider>
	);
};
