import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { ErrorMessage } from "@hookform/error-message";
import React, { useState } from "react";

import { useController } from "react-hook-form";
import useSWR from "swr";
import Tick from "../../../svgs/tick.svg";

async function fetchUsers([query, orgId]) {
	let url = `/app/organisations/search/${orgId}/employees/?q=${query}`;
	const response = await fetch(url);
	return response.json();
}

export const UserSearch = ({ value, name, orgId }) => {
	const [query, setQuery] = useState("");
	const { field, ref, fieldState: {error} } = useController({ name, value: [] });
	const [selectedPeople, setSelectedPeople] = useState([]);
	const { data, _ } = useSWR([query, orgId], fetchUsers);

	React.useEffect(() => {
		field.onChange(selectedPeople.flatMap((person) => person.id));
	}, [selectedPeople]);

	return (
		<div className="flex-col-5" ref={ref}>
			<Combobox
				value={selectedPeople}
				onChange={setSelectedPeople}
				multiple
				as="div"
				by="id"
				className="relative z-10"
				immediate
			>
				<ComboboxInput
					className={"autocomplete-input"}
					onChange={(event) => setQuery(event.target.value)}
					onPaste={(event) => setQuery(event.clipboardData.getData("text"))}
					placeholder="Search for a users name or email"
				/>
				<ComboboxOptions className="autocomplete">
					{data && data.length > 0 ? (
						data?.map((user) => (
							<ComboboxOption
								key={user.id}
								value={user}
								className="result-node"
							>
								{({ selected }) => {
									return (
										<>

											{user.name}{" "}
											{user.email && user.email != user.name
												? ` - (${user.email})`
												: ""}
											{selected && <Tick className="w-4" />}
										</>
									);
								}}
							</ComboboxOption>
						))
					) : (
						<li className="result-node">No results found</li>
					)}
				</ComboboxOptions>
			</Combobox>
			<div
				className={
					(selectedPeople.length > 0 ? "" : "opacity-0 hidden ") +
					"selected-container relative transition-opacity"
				}
			>
				<h2 className="search-select-label">Attendees ({selectedPeople.length} selected)</h2>
				<ul>
					{selectedPeople.map((person) => {
						return (
							<li className="selected-node" key={person.id}>
								<span>{person.name}</span>
								<button
									className="uppercase text-blue"
									onClick={() => {
										setSelectedPeople(
											selectedPeople.filter((p) => p.id !== person.id)
										);
									}}
								>
									Remove
								</button>
							</li>
						);
					})}
				</ul>
			</div>
			 {error && <div  className="flex flex-col gap-3 div">
				<ErrorMessage
					name={name}
					render={({ message, messages }) =>
						message ?
						(
							<span className="text-red" dangerouslySetInnerHTML={{__html: message['message'] }} />
						) :
						messages && Object.entries(messages).map(([type, message]) => (
							<span key={type} className="flex gap-3 leading-tight text-red [&_svg:mt-3]" dangerouslySetInnerHTML={{__html: message}} />
						))
					}
				/>
			</div>}
		</div>
	);
};
